@import './colors.scss';
@import './extensions.scss';

@font-face {
  font-family: 'Circular';
  src: url('../assets/fonts/CircularStd-Book.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Circular';
  src: url('../assets/fonts/CircularStd-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Circular';
  src: url('../assets/fonts/CircularStd-Bold.woff') format('woff');
  font-weight: 700;
}

@font-face {
  font-family: 'Circular';
  src: url('../assets/fonts/CircularStd-Black.woff') format('woff');
  font-weight: 900;
}

// opinionated selector taken from sanitize.css
*,
::before,
::after {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

body {
  color: $base;
  font-family: 'Circular';
  font-size: 16px;
  margin: 0;
  padding: 0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

input {
  color: $base;
  font-family: 'Circular';
  font-size: 14px;
}

.caps {
  letter-spacing: 2.08px;
  text-transform: uppercase;
}

.link {
  cursor: pointer;
  user-select: none;
}

.link:hover {
  text-decoration: underline;
}

.ml-1 {
  margin-left: 1em;
}

.ml-1-25 {
  margin-left: 1.25em;
}
