@import '../../../styles/colors';
@import '../../../styles/extensions';

.Login-form-forgot-password {
  text-align: center;
  font-size: 14px;
}

.Login-form-clickable-span {
  @extend %bold;
  @extend %clickable;
  text-transform: uppercase;

  &:hover {
    text-decoration: underline;
  }
}
