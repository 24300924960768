.CenteredParagraph {
  margin: 1em 0 2em;
  text-align: center;
  padding: 0 20px;
}

.Header {
  font-size: 42px;
  font-weight: 900;
  letter-spacing: -0.54px;
  text-align: center;
}

.Superheader {
  letter-spacing: 2.08px;
  text-align: center;
  text-transform: uppercase;
}
