@import './colors.scss';

%bold {
  font-weight: 700;
  letter-spacing: 2.08px;
}

%capsule {
  border-radius: 999px;
}

%card {
  background-color: $white;
  border-radius: 10px;
  box-shadow: 0 0 30px rgba(71, 71, 71, 0.2);
}

%centered {
  align-items: center;
  display: flex;
  justify-content: center;
}

%clickable {
  cursor: pointer;
  user-select: none;
}

%outlined {
  @extend %capsule;
  border: 1px solid;
  bottom: -4px;
  content: '';
  left: -4px;
  position: absolute;
  right: -4px;
  top: -4px;
  transition-duration: 0.2s;
  transition-property: border-color;
}
