@import '../../../styles/colors';
@import '../../../styles/extensions';

.NavigationLink {
  @extend %centered;
  background-color: $white;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  height: 50px;
  overflow: hidden;
  position: relative;
  text-transform: uppercase;
  user-select: none;
  & svg {
    height: 22px;
  }
}

.NavigationLink-label {
  letter-spacing: 2.6px;
  margin-left: 10px;
  transition-duration: 0.2s;
  transition-property: color;
}

.NavigationLink-counter {
  @extend %capsule;
  align-items: center;
  color: $white;
  display: flex;
  height: 30px;
  justify-content: center;
  margin-left: 16px;
  width: 30px;
}

.NavigationLink-indicator {
  display: flex;
  left: calc(50% - 8px);
  position: absolute;
  transition-duration: 0.2s;
  transition-property: bottom;
  width: 100%;
}

.NavigationLink-indicator > svg {
  display: block;
}

.NavigationLink-mobile {
  height: 40px;
}
