@import "../../styles/colors";
@import "../../styles/extensions";

.Toggle {
  display: flex;
}

.Toggle + .Toggle {
  margin-top: 1em;
}

.Toggle-slider {
  @extend %capsule;
  background-color: $gray;
  height: 20px;
  position: relative;
  width: 40px;
}

.Toggle-slider-circle {
  @extend %capsule;
  height: 20px;
  position: absolute;
  transition-property: background-color, right;
  transition-duration: 0.2s;
  width: 20px;
}

.Toggle-slider-circle-active {
  background-color: $teal;
  right: 0;
}

.Toggle-slider-circle-inactive {
  background-color: $dark-gray;
  right: 50%;
}

.Toggle-label {
  margin-left: 0.5em;
}
