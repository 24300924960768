.flag-dropdown {
  border-radius: 20px 0 0 20px !important;
}
.selected-flag {
  border-radius: 20px 0 0 20px !important;
}

div[disabled] .flag-dropdown {
  background-color: #f3f5fb;
  border-color: #dfe3ea;
}
