$base: #38485c;
$base-brighter: #5f6d7c;
$base-darker: #2d3a4a;
$blue: #64ace2;
$dark-gray: #9ba3ad;
$medium-gray: #caced4;
$gray: #f1f2f5;
$green: #9fd592;
$red: #f97878;
$red-brighter: #f89392;
$red-darker: #e67374;
$teal: #92d5d5;
$teal-brighter: #bee6e6;
$white: #ffffff;
$yellow: #fad77d;
