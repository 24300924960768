@import './styles/extensions.scss';

.App {
  background-attachment: fixed;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.App-logged-in {
  background-image: url('./assets/graphics/background.svg');
}

.App-logged-out {
  background-image: url('./assets/graphics/loginBackground.svg');
}
.App-logged-out-single-task {
  background-image: url('./assets/graphics/background.svg');
}

.App-content {
  flex: 1;
  overflow-y: auto;
}

.custom-toast {
  padding: 10px 20px !important;
  min-width: 240px;
  min-height: 54px;
  max-width: 450px;
  font-size: 14px;
  color: white;
  border-radius: 5px !important;
  background-color: #64ace2;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: white;
    fill: white !important;
  }
}

.Toastify__toast--error {
  background-color: rgb(249, 88, 89) !important;
}

.Toastify__toast--warning {
  background-color: #fa953a !important;
}

.Toastify__toast--success {
  background-color: #38485c !important;
}

.Toastify__toast--nag {
  background-color: #92d5d5 !important;
}

.react-tooltip-lite {
  background: $base-darker;
  color: white;
  font-size: 12px;
  border-radius: 3px;
}

.react-tooltip-lite-arrow {
  border-color: $base-darker;
}
