.LoggedOutFormContainer-Form {
  width: 100%;
  max-width: 500px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px 20px 50px;
}

.LoggedOutFormContainer-Title {
  font-size: 42px;
  font-weight: 900;
  letter-spacing: -0.54px;
  margin: 0 0 40px;
  text-align: center;
}

.LoggedOutFormContainer-Button {
  margin-top: 20px;
}
