@import '../../styles/colors';

.Navigation {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 260px;
}

.Navigation-Mobile {
  width: 100%;
  background-color: $white;
  height: 40px;
  justify-content: space-around;

  .NavigationLink-mobile {
    width: 50%;
    border-top: 1px solid #eee;
  }
  .AddButton {
    position: absolute;
    z-index: 2;
  }
}

.Navigation-Desktop {
  margin-left: 105px;
}
