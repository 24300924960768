@import '../../styles/colors';

$size: 8px;

.LoadingIndicator {
  height: $size;
  text-align: center;
  width: 100%;

  & > div {
    animation: pulse 1200ms infinite ease-in-out;
    border-radius: 100%;
    display: inline-block;
    height: $size;
    width: $size;

    &.LoadingIndicator-1 {
      animation-delay: -200ms;
      background-color: $blue;
    }

    &.LoadingIndicator-2 {
      animation-delay: -100ms;
      background-color: $red;
    }

    &.LoadingIndicator-3 {
      background-color: $teal;
    }
  }

  & > div + div {
    margin-left: $size;
  }
}

@keyframes pulse {
  0%,
  95%,
  100% {
    transform: scale(0);
  }

  47.5% {
    transform: scale(1);
  }
}
