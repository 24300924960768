.react-calendar {
  width: 250px;
  border-radius: 5px;
  font-size: 12px;
  border-color: #c6c6c6;
}
.react-calendar__navigation {
  height: 30px;
  margin-bottom: 0;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 10px;
}

.react-calendar__tile--now {
  background: #64ace2 !important;
  color: white;
}
.react-calendar__tile--now:hover {
  background: #649dd3 !important;
  color: white;
}
.react-calendar__month-view__days__day--weekend {
  color: #38485c;
}
.react-calendar__tile--active {
  background: #2d3a4a !important;
  color: white;
}
.react-calendar__tile--active:hover {
  background: #425368 !important;
  color: white;
}
