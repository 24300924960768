@import '../../styles/colors';
@import '../../styles/extensions';
.Logo {
  @extend %clickable;
  height: 23px;
  & svg {
    height: 23px;
    width: 100px;
  }
}
.HeaderBar {
  align-items: center;
  background-color: $white;
  box-shadow: 0 0 30px rgba(71, 71, 71, 0.2);
  display: flex;
  height: 50px;
  padding: 0 25px;
  z-index: 5;
  font-weight: 600;
}
.HeaderBar-mobile {
  padding: 0 20px;
}
